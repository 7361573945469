import { TweenLite } from 'gsap/all';
import { triggerCustomEvent } from './utils';

document.addEventListener('DOMContentLoaded', () => {
    const tabWrappers = Array.from(document.querySelectorAll('.js-tab-wrapper'));
    tabWrappers.forEach(wrapper => {
        const ACTIVE_CLASS = 'tab--active';
        const ACTIVE_CONTROL_CLASS = 'tab-control--active';
        const tabs = Array.from(wrapper.querySelectorAll('.tab'));
        const controls = Array.from(wrapper.querySelectorAll('.tab-control'));

        function showTab(event) {
            event.preventDefault();
            const { href } = this;
            const tabName = href.substr(href.indexOf('#') + 1);

            if (this.classList.contains('js-entity-type')) {
                localStorage.setItem('entity_type', tabName)
            }

            if (!tabName) {
                console.warn('Please provide the tab name');
            }

            controls.forEach((control) => control.classList.remove(ACTIVE_CONTROL_CLASS));
            this.classList.add(ACTIVE_CONTROL_CLASS);

            if (this.dataset.lng && this.dataset.lat) {
                triggerCustomEvent(document, 'tab-change', { lat: this.dataset.lat, lng: this.dataset.lng });
            }

            const activeTab = tabs.find((tab) => tab.classList.contains(ACTIVE_CLASS));
            const tabToShow = tabs.find((tab) => tab.dataset.tab === tabName);

            if (tabToShow === activeTab) {
                return;
            }

            TweenLite.to(activeTab || {}, 0.5, {
                x: 30,
                opacity: 0,
                onComplete: () => {
                    if (activeTab) {
                        activeTab.classList.remove(ACTIVE_CLASS);
                    }

                    if (tabToShow) {
                        tabToShow.classList.add(ACTIVE_CLASS);
                        TweenLite.fromTo(tabToShow, 0.5, { x: -30, opacity: 0 }, { x: 0, opacity: 1 });
                    } else {
                        console.warn('No tab found.');
                    }
                },
            });
        }

        controls.forEach((control) => {
            control.addEventListener('click', showTab);
        });

        if (controls.length > 0 && localStorage.getItem('entity_type')) {
            const control = controls.find((control) => {
                return control.href.substr(control.href.indexOf('#') + 1) === localStorage.getItem('entity_type')
            })

            if (control) {
                control.click();
            }
        }
    });
});

import { Swiper, Scrollbar } from 'swiper';

function init(container = document) {
    const sliderElements = Array.from(container.querySelectorAll('.js-gallery-slider'));
    sliderElements.forEach((el) => {
        if (Array.from(el.querySelectorAll('.swiper-slide')).length > 1) {
            const slider = new Swiper(el, {
                modules: [Scrollbar],
                slidesPerView: 'auto',
                spaceBetween: 10,
                scrollbar: {
                    el: ".swiper-scrollbar",
                    hide: false,
                    draggable: true
                },
                breakpoints: {
                    768: {
                        spaceBetween: 40,
                    },
                    1025: {
                        spaceBetween: 28,
                    },
                    1441: {
                        spaceBetween: 40,
                    }
                }
            });
        }
    });
}

const _module = { init };

export default _module;

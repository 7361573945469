import ContentList from '@chipsadesign/content-list';
import ContentListFilter from '@chipsadesign/content-list/dist/modules/ContentListFilter';
import { lazyload } from './lazyload';
import revealInit from './reveal';
import initSelects from './selects';

export default function initContentList() {
    const catalogEl = document.querySelector('.js-catalog');
    const filterContainer = document.querySelector('.js-catalog-filter');

    if (catalogEl) {
        const loadMoreButton = document.querySelector('.js-load-more-button');

        new ContentList(catalogEl, {
            modules: filterContainer ? [ContentListFilter] : [],
            // Параметры
            filter: filterContainer
                ? {
                    el: filterContainer,
                    filterOnInputChange: true,
                    inputDebounceTime: 1000,
                }
                : null,
            onFetchSuccess: (instance, response) => {
                // Пример замены контента
                if (response.data.method === 'append') {
                    instance.contentEl.innerHTML += response.data.contentHtml;
                } else if (response.data.method === 'insert') {
                    instance.contentEl.innerHTML = response.data.contentHtml;
                }

                if (response.data.filterHtml && filterContainer) {
                    filterContainer.innerHTML = response.data.filterHtml;
                    initSelects(filterContainer);
                }

                lazyload.update();
                revealInit(catalogEl);

                if (response.data.nextEndpoint && loadMoreButton) {
                    loadMoreButton.setAttribute('href', response.data.nextEndpoint);
                }
                if (!response.data.nextEndpoint && loadMoreButton) {
                    loadMoreButton.remove();
                }
            },
        });
    }
}

import './YandexMap.scss';
import { LitElement, html } from 'lit';
import loadScript from '../../../modules/load-script';

export class YandexMap extends LitElement {
    constructor() {
        super();

        this._closeCard = this._closeCard.bind(this);

        this.instance = null;
        this.zoom = 11;
        this._isFetching = false;
        this.currentCoords = [0, 0];
        this.data = this.getAttribute('map-data') ? JSON.parse(this.getAttribute('map-data')) : null;
        this.marks = [];
        this.activeTabs = [];
        this.clusterer;
        this.isCardVisible = false;

        this._observer = new IntersectionObserver((entries, obs) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    obs.unobserve(entry.target);
                    this._init();
                }
            });
        });
    }

    static get properties() {
        return {
            centerLat: {
                type: Number,
                attribute: 'center-lat',
                reflect: true,
            },
            centerLng: {
                type: Number,
                attribute: 'center-lng',
                reflect: true,
            },
            apiKey: {
                type: String,
                attribute: 'api-key',
            },
            zoom: {
                type: Number,
                reflect: true,
            },
            marker: {
                type: String,
                reflect: true,
            },
            data: {
                type: Object,
            },
            _isFetching: {
                type: Boolean,
                attribute: false,
            },
            _error: {
                attribute: false,
            },
            isCardVisible: {
                type: Boolean,
            },
        };
    }

    createRenderRoot() {
        return this;
    }

    _init() {
        this._error = null;
        this._isFetching = true;
        loadScript(`https://api-maps.yandex.ru/2.1/?apikey=${this.apiKey}&lang=ru_RU`)
            .then(() => {
                const { ymaps } = window;
                ymaps.ready(() => {
                    const _this = this;
                    const margin = window.matchMedia('(max-width: 1024px)').matches ? 50 : [50, 50, 50, 400];
                    this.instance = new ymaps.Map(this.renderRoot.querySelector('.map'), {
                        center: [this.centerLat, this.centerLng],
                        zoom: this.zoom,
                        margin: margin
                    });

                    const zoomControl = new ymaps.control.ZoomControl({
                        options: {
                            position: {
                                left: 'auto',
                                right: 10,
                                top: 60
                            }
                        }
                    });
                    this.instance.controls.add(zoomControl);

                    const elements = [];

                    if (this.data) {
                        if (this.data.placemarks?.[0]?.coords) {
                            this.currentCoords = this.data.placemarks[0].coords;
                        }

                        this.data.placemarks.forEach((placemark) => {
                            const animatedLayout = ymaps.templateLayoutFactory.createClass(
                                `<div class="map-placemark js-map-placemark" data-coords="${placemark.coords}">
                                    <div class="map-placemark__button js-map-marker">
                                        <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9882 16.8428C12.8937 15.6136 12.941 14.5735 12.941 13.3207C12.941 13.0134 13.673 12.7061 14.2634 12.3751C14.9482 11.9733 18.4196 9.89308 18.6558 9.89308C18.8683 9.89308 22.9065 12.2806 23.7566 12.7533C24.04 12.9188 24.3942 13.0134 24.3942 13.3207C24.3942 14.4553 24.4414 15.7318 24.347 16.8428C23.8983 16.5355 18.8447 13.628 18.6558 13.628C18.3724 13.628 13.6022 16.4882 12.9882 16.8428ZM1.55858 10.8623V7.12737C2.40872 7.50559 6.84832 10.3422 7.24977 10.3422C7.67484 10.3659 18.2543 3.91253 18.703 3.84161C18.8919 3.81798 23.8747 6.72552 24.4414 7.05646L24.465 10.7913C23.5677 10.4131 19.0808 7.60014 18.7502 7.57651C18.5377 7.57651 13.6494 10.4368 13.0118 10.815C12.4214 11.1696 7.48592 14.0535 7.32062 14.0771C6.99001 14.1244 2.36149 11.2405 1.55858 10.8623ZM1.53497 4.66896L1.55858 1.12318C2.17257 1.47776 6.99001 4.31439 7.17893 4.33802C7.72207 4.40894 11.9255 1.54867 12.9882 1.17046V4.85807C12.2325 5.37812 7.43869 8.12019 7.17893 8.09655L2.14896 5.23629C1.84196 5.04718 1.53497 4.9999 1.53497 4.66896ZM0 17.9302C2.03088 18.072 8.78474 17.9538 11.2879 17.9538L26 17.9302V0.0594461C24.7956 -0.0587467 3.84923 0.0358075 0 0.0358075V17.9302Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <div class="map-card">
                                        <button
                                            class="card-close-btn"
                                            aria-label="Закрыть"
                                            @click="${this._closeCard}"
                                        >
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 1L11 11M1 11L11 1" stroke="#3B3B3B"/>
                                            </svg>
                                        </button>
                                        <div class="map-card__inner">
                                            <div class="map-card__title">
                                                ${placemark.address ? placemark.address : ''}
                                            </div>
                                            <div class="map-card__time">
                                                ${placemark.working_mode ? placemark.working_mode : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>`,
                                {
                                    build() {
                                        animatedLayout.superclass.build.call(this);

                                        const element = this.getParentElement().getElementsByClassName('map-placemark')[0];
                                        elements.push(element);
                                        // Если метка выбрана, то увеличим её размер.
                                        const size = 40;
                                        // Зададим фигуру активной области.
                                        this.getData().options.set('shape', {
                                            type: 'Circle',
                                            coordinates: [size / 2, size / 2],
                                            radius: size / 2,
                                        });
                                        // Если метка выбрана, то зададим класс и запустим анимацию.
                                        if (this.isActive) {
                                            element.classList.add('is-active');
                                        } else if (this.inited) {
                                            element.classList.remove('is-active');
                                        }

                                        const closeAll = () => {
                                            _this.isCardVisible = false;
                                            if (_this.activeMark && _this.activeMark !== this) {
                                                _this.activeMark.isActive = false;
                                            }
                                            _this.renderRoot.querySelector('.map-card.is-active')?.classList.remove('is-active');
                                            elements.forEach((el) => {
                                                el.classList.remove('is-active');
                                            });
                                        };

                                        _this.addEventListener('overlay-click', closeAll);

                                        if (!this.inited) {
                                            this.inited = true;
                                            this.isActive = false;

                                            // При клике по метке будем перестраивать макет.
                                            this.getData().geoObject.events.add(
                                                'click',
                                                function (event) {
                                                    closeAll();
                                                    this.isActive = !this.isActive;
                                                    _this.isCardVisible = this.isActive;
                                                    const mark = event.get('target');
                                                    const index = _this.marks.indexOf(mark);

                                                    if (this.isActive) {
                                                        element.classList.add('is-active');

                                                        if (index > -1) {
                                                            element.querySelector('.map-card').classList.add('is-active');
                                                        }
                                                    }

                                                    _this.activeMark = this;

                                                    if (window.matchMedia('(max-width: 767px)').matches) {
                                                        const coords = this._data?.geometry?._coordinates;
                                                        if (coords && _this.instance) {
                                                            // сдвигаем карту чуть ниже и левее, чтобы было видно поп ап
                                                            _this.instance.setCenter([Number(coords[0]) + 0.05, Number(coords[1]) + 0.08])
                                                        }
                                                    }
                                                },
                                                this,
                                            );
                                            this.getData().geoObject.events.add(
                                                'mouseenter',
                                                function () {
                                                    element.classList.add('is-hovered');
                                                },
                                                this,
                                            );
                                            this.getData().geoObject.events.add(
                                                'mouseleave',
                                                function () {
                                                    element.classList.remove('is-hovered');
                                                },
                                                this,
                                            );
                                        }
                                    },
                                },
                            );

                            const markOptions = {
                                iconLayout: animatedLayout,
                                hasHint: false,
                                hasBalloon: false,
                            };
                            const mark = new ymaps.Placemark(placemark.coords, {}, markOptions);
                            this.marks.push(mark);
                            this.instance.geoObjects.add(mark);
                        });

                        this.dispatchEvent(new Event('init'));

                        this.clusterer = new ymaps.Clusterer({
                            clusterIconLayout: ymaps.templateLayoutFactory.createClass(`
                                <div class="cluster-icon">
                                    <span class="cluster-icon__count">{{ properties.geoObjects.length }}</span>
                                    <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9882 16.8428C12.8937 15.6136 12.941 14.5735 12.941 13.3207C12.941 13.0134 13.673 12.7061 14.2634 12.3751C14.9482 11.9733 18.4196 9.89308 18.6558 9.89308C18.8683 9.89308 22.9065 12.2806 23.7566 12.7533C24.04 12.9188 24.3942 13.0134 24.3942 13.3207C24.3942 14.4553 24.4414 15.7318 24.347 16.8428C23.8983 16.5355 18.8447 13.628 18.6558 13.628C18.3724 13.628 13.6022 16.4882 12.9882 16.8428ZM1.55858 10.8623V7.12737C2.40872 7.50559 6.84832 10.3422 7.24977 10.3422C7.67484 10.3659 18.2543 3.91253 18.703 3.84161C18.8919 3.81798 23.8747 6.72552 24.4414 7.05646L24.465 10.7913C23.5677 10.4131 19.0808 7.60014 18.7502 7.57651C18.5377 7.57651 13.6494 10.4368 13.0118 10.815C12.4214 11.1696 7.48592 14.0535 7.32062 14.0771C6.99001 14.1244 2.36149 11.2405 1.55858 10.8623ZM1.53497 4.66896L1.55858 1.12318C2.17257 1.47776 6.99001 4.31439 7.17893 4.33802C7.72207 4.40894 11.9255 1.54867 12.9882 1.17046V4.85807C12.2325 5.37812 7.43869 8.12019 7.17893 8.09655L2.14896 5.23629C1.84196 5.04718 1.53497 4.9999 1.53497 4.66896ZM0 17.9302C2.03088 18.072 8.78474 17.9538 11.2879 17.9538L26 17.9302V0.0594461C24.7956 -0.0587467 3.84923 0.0358075 0 0.0358075V17.9302Z" fill="white"/>
                                    </svg>
                                </div>`
                            ),

                            // Чтобы метка была кликабельной, переопределим ее активную область.
                            clusterIconShape: {
                                type: 'Rectangle',
                                coordinates: [[0, 0], [60, 60]],
                            },
                            hasBalloon: false,
                            hasHint: false,
                            gridSize: 128,
                        });

                        this.clusterer.add(this.marks);
                        this.instance.geoObjects.add(this.clusterer);
                    }

                    this.instance.behaviors.disable('scrollZoom');

                    this.instance.events.add('click', this._closeCard);

                    window.addEventListener('resize', this._onResize);

                    const mapLinks = document.querySelectorAll('.js-map-link');
                    mapLinks.forEach(link => link.addEventListener('click', () => this._setCoords(link)));

                    // смотрим, еслть ли офис в городе и открываем попап этого офиса
                    this._findOffice();
                });
            })
            .catch((err) => {
                this._error = err;
                throw err;
            })
            .finally(() => {
                this._isFetching = false;
            });
    }

    connectedCallback() {
        super.connectedCallback();

        if (!this.apiKey) {
            throw new Error('API key not provided.');
        }

        this._observer.observe(this);
    }

    attributeChangedCallback(name, oldVal, newVal) {
        super.attributeChangedCallback(name, oldVal, newVal);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this._observer.disconnect();
        window.removeEventListener('resize', this._onResize);
        this._error = null;

        if (this.instance) {
            this.instance.destroy();
            this.instance = null;
        }
    }

    _onResize() {
        this.instance?.container.fitToViewport();
    }

    _renderMap() {
        if (this._isFetching) {
            return html`<div class="map-loader">Загружаем карту...</div>`;
        }

        if (this._error) {
            return html`<div>${this._error.message}</div>`;
        }

        return '';
    }

    _closeCard() {
        this.dispatchEvent(new Event('overlay-click', { composed: true }));
    }

    _hoverPoint(coords) {
        setTimeout(() => {
            const placemarksOnMap = document.querySelectorAll('.js-map-placemark');
            placemarksOnMap.forEach((mark) => {
                const markCoords = mark.dataset.coords ? mark.dataset.coords.split(',') : [];
                if (markCoords.length && markCoords[0] == coords[0] && markCoords[1] == coords[1]) {
                    mark.classList.add('is-hovered', 'is-active');

                    // показываем попап
                    const pointPopup = mark.querySelector('.map-card');
                    if (pointPopup) pointPopup.classList.add('is-active');

                    // сдвигаем карту чуть ниже и левее, чтобы было видно поп ап
                    if (window.matchMedia('(max-width: 767px)').matches) {
                        if (this.instance) {
                            this.instance.setCenter([Number(coords[0]) + 0.05, Number(coords[1]) + 0.08])
                        }
                    }
                } else {
                    mark.classList.remove('is-hovered');
                }
            })
        }, 200);
    }

    _findOffice() {
        // Если у офиса есть адрес (режим работы), то открываем попап офиса
        const offices = document.querySelectorAll('.js-map-search-office:not(.is-hide)');
        if (offices.length == 1) {
            const address = offices[0].querySelector('.js-work-time');

            if (address) {
                const coordsContainer = offices[0].querySelector('.js-map-link');
                const officeCoords = coordsContainer.dataset.coords ? JSON.parse(coordsContainer.dataset.coords) : null;

                if (officeCoords) {
                    this._hoverPoint(officeCoords);
                }
            }
        }
    }

    _setCoords(link) {
        const coords = link.dataset.coords ? JSON.parse(link.dataset.coords) : null;
        if (this.instance && coords) {
            link.classList.contains('js-map-link-city') ? this.instance.setCenter(coords, 10) : this.instance.setCenter(coords, 15);
            // если это кнопка "Показать на карте"
            if (!link.classList.contains('js-map-link-city')) {
                this._hoverPoint(coords);
            } else {
                // если это выбор города
                this._findOffice();
            }
        }
    }

    render() {
        return html` <div class="map-container">
            <div class="map">${this._renderMap()}</div>
        </div>`;
    }
}

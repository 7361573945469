import throttle from 'lodash.throttle';

document.addEventListener('DOMContentLoaded', () => {
    const stickyHeader = document.querySelector('.js-sticky-header');

    if (!stickyHeader) {
        return;
    }

    let scrollTop = Math.max(0, window.pageYOffset);
    let headerHeight = stickyHeader.offsetHeight;
    const threshold = 120;

    const throttledHandleStickyHeader = throttle(() => {
        const newScrollTop = Math.max(0, window.pageYOffset);

        if (Math.abs(newScrollTop - scrollTop) < threshold) {
            scrollTop = newScrollTop;
            return;
        }

        const scrollAfterHeader = (newScrollTop > headerHeight);
        const headerState = (newScrollTop > scrollTop && scrollAfterHeader);

        scrollTop = newScrollTop;

        if (!headerState) {
            if (stickyHeader.classList.contains('sticky-header--is-hidden')) {
                stickyHeader.classList.remove('sticky-header--is-hidden');
            }
        } else if (!stickyHeader.classList.contains('sticky-header--is-hidden')) {
            stickyHeader.classList.add('sticky-header--is-hidden');
        }
    }, 150);

    const onResize = () => {
        if (!stickyHeader) {
            return;
        }

        headerHeight = stickyHeader.offsetHeight;
    };

    window.addEventListener('scroll', throttledHandleStickyHeader);
    window.addEventListener('resize', onResize);
});

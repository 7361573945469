export default function revealInit(container = document) {
    const revealItemsObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                revealItemsObserver.unobserve(entry.target);
                entry.target.classList.add('is-reveal');
            }
        });
    });

    Array.from(container.querySelectorAll('.js-reveal')).forEach((el) => {
        revealItemsObserver.observe(el);
    });
}

import delegate from 'delegate';
import gallery from './gallery';
import revealInit from './reveal';

const getPopupContent = async (url, inner, trigger) => {
    if (!url || !inner) return;
    setTimeout(() => {
        if (trigger) trigger.classList.add('is-loading');
    }, 300);

    await fetch(url, {
        method: 'POST',
    })
        .then(response => response.json())
        .then(data => {
            trigger?.remove();
            if (data.data.listHTML) inner.innerHTML += data.data.listHTML;
            gallery.init();
            revealInit();
        })
};

const onClick = (e) => {
    e.preventDefault();

    const trigger = e.delegateTarget;
    const url = trigger.href;
    const inner = trigger.closest('.js-load-content');

    getPopupContent(url, inner, trigger)
}

function init() {
    delegate('.js-load-more', 'click', onClick);
}

const _module = { init };

export default _module;

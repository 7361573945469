import { TimelineLite /* , Power1 */, Power2 } from 'gsap/all';

const ACTIVE_NAV_LINK = 'nav__link--active';

export default () => {
    const counter = document.querySelector('.js-screen-counter');
    if (counter) {
        setTimeout(() => {
            counter.classList.add('screen-counter--animated');
        }, 500);
    }

    if (!window.location.hash || window.location.hash === '#main') {
        const tl = new TimelineLite({
            onComplete: () => {
                tl.kill();
            },
        });

        const isMobile = window.matchMedia('(max-width: 1024px)').matches;
        const arrow = document.querySelector('.js-entering__arrow');

        // if (document.body.classList.contains('index-page')) {
        //     setTimeout(() => {
        //         const activeLink = document.querySelector(`.nav__link.${ACTIVE_NAV_LINK}`)
        //             || document.querySelector('.nav__link:first-child');

        //         if (activeLink) {
        //             activeLink.classList.add(ACTIVE_NAV_LINK);
        //         }
        //     }, 500);
        // }

        tl.from(['.js-entering__aside', ...(isMobile ? [] : ['.js-entering-logo'])], 1.5, {
            x: -50,
            ease: Power2.easeOut,
        })
            .staggerFrom(
                isMobile ? {} : '.js-entering__nav-item',
                isMobile ? 0 : 1.2,
                { opacity: 0, y: -40, ease: Power2.easeOut },
                0.15,
                '-=1.3',
            )
            // .from('.js-entering__text-1-container', 0.6, { xPercent: -100 }, '-=0.6')
            // .from('.js-entering__text-1-container', 0.6, { opacity: 0 }, '-=0.6')
            // .from('.js-entering__text-1', 0.6, { xPercent: 100 }, '-=0.6')
            // .from('.js-entering__text-2-container', 1.5, { xPercent: -100 }, '-=0.2')
            // .from('.js-entering__text-2-container', 1.5, { opacity: 0 }, '-=0.2')
            // .from('.js-entering__text-2', 1.5, { xPercent: 100 }, '-=1.5')
            // .from('.js-entering-separator', 1.5, { yPercent: 100, ease: Power1.easeOut }, '-=0.6')
            // .from('.js-entering__text-3-container', 1.5, { xPercent: -100 }, '-=0.5')
            // .from('.js-entering__text-3-container', 1.5, { opacity: 0 }, '-=0.5')
            // .from('.js-entering__text-3', 1.5, { xPercent: 100 }, '-=1.5')
            // .from('.js-entering__text-4-container', 1.2, { xPercent: -100 }, '-=0.7')
            // .from('.js-entering__text-4-container', 1.2, { opacity: 0 }, '-=0.7')
            // .from('.js-entering__text-4', 1.2, { xPercent: 100 }, '-=1.2')
            // .from('.js-entering__text-5-container', 1.5, { xPercent: -100 }, '-=0.7')
            // .from('.js-entering__text-5-container', 1.5, { opacity: 0 }, '-=0.7')
            // .from('.js-entering__text-5', 1.5, { xPercent: 100 }, '-=1.5')
            .staggerFrom(
                isMobile ? {} : '.a-header-right',
                isMobile ? 0 : 1.2,
                { opacity: 0, x: -20, ease: Power2.easeOut },
                0.15,
                '-=1.5',
            )
            .from(
                '.js-entering__arrow',
                1.5,
                {
                    yPercent: 100,
                    onComplete: () => {
                        if (arrow) {
                            arrow.classList.add('animation__next-slide-btn');
                        }
                    },
                },
                '-=1.5',
            );
    }
};

const header = document.querySelector('.js-header');
const commonRootMargin = '-10% 0% -90% 0%';
const themes = [
    'light',
    'dark',
    'dark-transparent',
    'light-transparent',
    'yellow',
    'blue',
    'brown',
    'light-brown',
    'body',
    'beige-noise',
    'grey',
    'light',
    'light-grey',
];
const callback = (entries) => {
    entries.forEach((entry) => {
        if (header && entry.isIntersecting) {
            const { headerTheme } = entry.target.dataset;

            if (!headerTheme) {
                themes.forEach((item) => {
                    header.classList.remove(`header--${item}`);
                });
                return;
            }

            themes
                .filter((item) => item !== headerTheme)
                .forEach((item) => {
                    header.classList.remove(`header--${item}`);
                });
            header.classList.add(`header--${headerTheme}`);
        }
    });
};

const map = new WeakMap();

const commonObserver = new IntersectionObserver(callback, { rootMargin: commonRootMargin, threshold: [0, 1] });

function init() {
    const elements = Array.from(document.querySelectorAll('[data-header-theme]'));
    document.documentElement.style.setProperty('--header-height', `${header.offsetHeight}px`);

    elements.forEach((el) => {
        const rootMargin = el.dataset.rootMargin || commonRootMargin;
        const observer =
            rootMargin === commonRootMargin
                ? commonObserver
                : new IntersectionObserver(callback, { rootMargin, threshold: [0, 1] });

        observer.observe(el);
        map.set(el, observer);
    });
}

function destroy() {
    const elements = Array.from(document.querySelectorAll('[data-header-theme]'));

    elements.forEach((el) => {
        const observer = map.get(el);

        if (observer) {
            observer.unobserve(el);
        }
    });
}

export default { init, destroy };

export default (contextElement = document) => {
    const inputs = Array.from(contextElement.querySelectorAll('.floated-labels'));

    if (!inputs) {
        return {
            destroy: () => {},
        };
    }

    function handleFloatedLabel() {
        if (this.value.length > 0 || this === document.activeElement) {
            this.parentElement.classList.add('floated-labels--active');
        } else {
            this.parentElement.classList.remove('floated-labels--active');
        }
    }

    function addFocus() {
        this.parentElement.classList.add('floated-labels--active');
    }

    function handleFocus() {
        if (this.value.length === 0) {
            this.parentElement.classList.remove('floated-labels--active');
        }
    }

    inputs.forEach((input) => {
        input.addEventListener('change', handleFloatedLabel);
        input.addEventListener('focus', addFocus);
        input.addEventListener('blur', handleFocus);
    });

    function destroy() {
        inputs.forEach((input) => {
            input.removeEventListener('change', handleFloatedLabel);
            input.removeEventListener('focus', addFocus);
            input.removeEventListener('blur', handleFocus);
        });
    }

    return Object.freeze({
        destroy,
    });
};

const fetchIMask = async () => import('imask' /* webpackChunkName: "imask" */).then((m) => m.default);

async function initPhoneMask(input, dialCode = '7') {
    const IMask = await fetchIMask();
    const imask = IMask(input, {
        mask: `+{${dialCode}} (000)-000-00-00`,
        prepare: (appended, masked) => {
            if (appended === '8' && masked.value === '') {
                return '';
            }
            return appended;
        },
    });
}

document.addEventListener('DOMContentLoaded', () => {
    const elements = Array.from(document.querySelectorAll('.js-mask--phone'));

    if (elements.length > 0) {
        elements.forEach((el) => {
            initPhoneMask(el);
        });
    }
});

import throttle from 'lodash.throttle';

export default function actionsButtonInit() {
    const actionsWrapper = document.querySelector('.js-actions-wrapper');

    if (actionsWrapper) {
        let scrollTop = Math.max(0, window.pageYOffset);
        let actionsWrapperHeight = actionsWrapper.offsetHeight;
        const threshold = 120;

        const throttledHandleStickyActionsWrapper = throttle(() => {
            const newScrollTop = Math.max(0, window.pageYOffset);

            if (Math.abs(newScrollTop - scrollTop) < threshold) {
                scrollTop = newScrollTop;
                return;
            }

            const scrollAfterActionsWrapper = newScrollTop > actionsWrapperHeight;
            const actionsState = newScrollTop > scrollTop && scrollAfterActionsWrapper;

            scrollTop = newScrollTop;

            if (!actionsState) {
                if (actionsWrapper.classList.contains('is-hidden')) {
                    actionsWrapper.classList.remove('is-hidden');
                }
            } else if (!actionsWrapper.classList.contains('is-hidden')) {
                actionsWrapper.classList.add('is-hidden');
            }
        }, 150);

        const onResize = () => {
            if (!actionsWrapper) {
                return;
            }

            actionsWrapperHeight = actionsWrapper.offsetHeight;
        };

        window.addEventListener('scroll', throttledHandleStickyActionsWrapper);
        window.addEventListener('resize', onResize);

        const actionsLink = actionsWrapper.querySelector('.js-actions-link-mobile');
        const originalActionsLink = document.querySelector('.js-actions-link');

        if (actionsLink && originalActionsLink) {
            const href = originalActionsLink.getAttribute('href');
            actionsLink.setAttribute('href', href);
        }
    }
}

import Glide from '@glidejs/glide';
import createArrows from '../utils/create-arrows';
import createCounter from '../utils/create-counter';
import withLeadingZero from '../utils/with-leading-zero';
import { TweenLite } from 'gsap/all';

function init(container = document) {
    const sliderElements = Array.from(container.querySelectorAll('.js-service-slider'));
    const duration = 1.5;
    sliderElements.forEach((el) => {
        if (Array.from(el.querySelectorAll('.glide__slide')).length > 1) {
            createArrows(el, { arrowWrapperClass: 'arrows-middle', color: 'E4E2DF', arrowClass: 'btn-light-border' });
            createCounter(el, Array.from(el.querySelectorAll('.glide__slide')).length);
            const counterCurrent = el.querySelector('.js-slider-counter__current');
            const slider = new Glide(el, { animationDuration: 1500, gap: 0 });
            slider.on('run', () => {
                TweenLite.to(counterCurrent, duration * 0.1, {
                    y: -10,
                    opacity: 0,
                    onComplete: () => {
                        counterCurrent.textContent = withLeadingZero(slider.index + 1);
                        TweenLite.fromTo(counterCurrent, duration * 0.7, { y: 15, opacity: 0 }, { y: 0, opacity: 1 });
                    },
                });
            });
            slider.mount();
        }
    });
}

function destroy(container = document) {}

export default { init, destroy };

export default function initPreviewSwitch() {
    function handleClick(event) {
        const videoWrapper = event.currentTarget.nextElementSibling;
        const videoSrc = videoWrapper.getAttribute('data-src');

        if (!videoWrapper.querySelector('iframe')) {
            const iframe = document.createElement('iframe');
            iframe.setAttribute('src', `${videoSrc}?autoplay=1&muted=1`);
            iframe.setAttribute('allow', 'autoplay; encrypted-media');
            iframe.setAttribute('allowfullscreen', '');
            iframe.classList.add('responsive__item');
            videoWrapper.appendChild(iframe);
        }

        event.currentTarget.classList.add('overlay-is-hidden');
        videoWrapper.classList.add('video-active');
    }

    document.querySelectorAll('.video-overlay').forEach(element => {
        element.addEventListener('click', handleClick);
    });
}

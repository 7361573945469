import Glide from '@glidejs/glide';
import createArrows from '../utils/create-arrows';

export default function initAboutIndexSlider() {
    const sliderElement = document.querySelector('.js-about-index-slider');

    if (sliderElement) {
        const slides = Array.from(sliderElement.querySelectorAll('.glide__slide'));

        if (slides.length > 1) {
            createArrows(sliderElement, {
                arrowWrapperClass: 'about-index-slider__arrows',
                color: 'E4E2DF',
                arrowClass: 'btn-light-border',
            });
            const slider = new Glide(sliderElement, { animationDuration: 1500, gap: 0 });
            slider.mount();
        }
    }
}

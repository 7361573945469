export function setCount(number, setStarting = false) {
    const resultContainer = document.querySelector('.js-search-result-count');

    if (resultContainer) {
        resultContainer.textContent = `${setStarting ? 'Офисов' : 'Результатов'}: ${number}`;
    }
}

function init() {
    const mapSearchForm = document.querySelector('.js-map-search');
    if (mapSearchForm) {
        const input = mapSearchForm.querySelector('.js-map-search-input');
        const searchAddresses = document.querySelectorAll('.js-map-search-address');
        mapSearchForm.addEventListener('submit', (e) => {
            e.preventDefault();

            if (input.value && searchAddresses.length) {
                let countResults = 0;
                searchAddresses.forEach(address => {
                    const addressValue = address.textContent.toLocaleLowerCase();

                    // приводим всё к одному регистру
                    const searchValue = input.value.toLocaleLowerCase();
                    // разбиваем запрос на слова без лишних знаков
                    const arrWords = searchValue.split(/\, |\. |\ |\.|\,|\//);
                    let numberOfMatches = 0; // считаем кол-во совпадений

                    arrWords.forEach(word => {
                        if (addressValue.includes(word)) {
                            numberOfMatches++;
                        }
                    });

                    const addressBlock = address.closest('.js-map-search-office');
                    // если все слова есть в тексте, то отсавляем этот блок
                    if (numberOfMatches === arrWords.length) {
                        addressBlock?.classList.remove('is-hide');
                        countResults++;
                    } else {
                        addressBlock?.classList.add('is-hide');
                    }
                })
                setCount(countResults);

                // открываем попап с офисом
                const offices = document.querySelectorAll('.js-map-search-office:not(.is-hide)');
                if (offices.length == 1) {
                    const linkTrigger = offices[0].querySelector('.js-map-link');
                    linkTrigger.click();
                }
            } else if (input.value == '' && searchAddresses.length) {
                // если в инпуте ничего не заполнено, то выводятся все офисы
                const addressBlocks = document.querySelectorAll('.js-map-search-office');
                addressBlocks.forEach(addressBlock => {
                    addressBlock?.classList.remove('is-hide');
                })
                setCount(addressBlocks.length, true);
            }
        })
    }
}

const _module = { init };

export default _module;

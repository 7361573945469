import { fetchLightgallery } from '../dynamic-modules';

async function init() {
    const { default: lightGallery } = await fetchLightgallery();
    const galleryArr = document.querySelectorAll('.js-gallery');
    galleryArr.forEach((gallery) => {
        lightGallery(gallery, {
            speed: 500,
            selector: '.js-gallery-link',
            counter: false,
            animateThumb: false,
            download: false,
            toggleThumb: true,
            zoomFromOrigin: false,
            allowMediaOverlap: true,
            getCaptionFromTitleOrAlt: false,
            licenseKey: '1111-1111-111-1111',
        });
    });
}

const _module = { init };

export default _module;

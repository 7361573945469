/* global ymaps */
export default class Map {
    constructor(container) {
        this.container = container;
        this.centerCoords = [];

        ymaps.ready(() => {
            container.classList.remove('is-loading');
            this.onResize = this.onResize.bind(this);
        });
    }

    init() {
        const _init = () => {
            this.instance = new ymaps.Map(this.container, {
                center: this.centerCoords,
                // Уровень масштабирования. Допустимые значения:
                // от 0 (весь мир) до 19.
                zoom: 15,
                controls: [],
            });

            this.addMarker();
            window.addEventListener('resize', this.onResize);
        };

        if (ymaps.Map) {
            _init();
        } else {
            ymaps.ready(_init);
        }
    }

    destroy() {
        if (this.instance) {
            this.instance.destroy();
            this.instance = null;
        }
    }

    reinit() {
        this.destroy();
        this.init();
    }

    addMarker() {
        const mark = new ymaps.Placemark(this.centerCoords);
        this.instance.geoObjects.add(mark);
    }

    setCenter(coords) {
        if (!(Array.isArray(coords) && coords[0] && coords[1])) {
            throw new Error('[Map] Incorrect center coordinates.');
        }

        if (coords[0] === this.centerCoords[0] && coords[1] === this.centerCoords[1]) {
            return;
        }

        this.centerCoords = coords;
        this.reinit();
    }

    onResize() {
        if (this.instance) {
            this.instance.container.fitToViewport();
        }
    }
}

/* eslint global-require: "off" */
/* global NODE_ENV */
import 'focus-visible';
import 'element-matches-polyfill';

if (NODE_ENV === 'production') {
    if (!Object.assign) {
        Object.assign = require('object-assign');
    }

    require('picturefill');
}

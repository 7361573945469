import { Swiper, Scrollbar, FreeMode, Mousewheel, Navigation } from 'swiper';
import 'swiper/css';

function init(container) {
    const swiperContainer = container.querySelector('.js-best-keys-swiper');
    if (swiperContainer) {
        const slidesLength = Array.from(swiperContainer.querySelectorAll('.swiper-slide')).length;
        let isNeedToInitSwiper = false;

        const checkIsNeedToInitSwiper = () => {
            if (window.matchMedia('(min-width: 1280px)').matches) {
                isNeedToInitSwiper = slidesLength > 3;
            } else if (window.matchMedia('(max-width: 1279px) and (min-width: 768px)').matches) {
                isNeedToInitSwiper = slidesLength > 2;
            } else {
                isNeedToInitSwiper = slidesLength > 1;
            }
        };

        checkIsNeedToInitSwiper();

        window.addEventListener('resize', checkIsNeedToInitSwiper);
        if (isNeedToInitSwiper) {
            const instance = new Swiper(swiperContainer, {
                touchStartPreventDefault: false,
                modules: [Scrollbar, Mousewheel, Scrollbar, Navigation, FreeMode],
                mousewheel: false,
                scrollbar: {
                    el: '.swiper-scrollbar',
                    hide: false,
                    draggable: true,
                },
                freeMode: true,
                breakpoints: {
                    1920: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1280: {
                        speed: 400,
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1024: {
                        speed: 500,
                        slidesPerView: 2,
                        spaceBetween: 15,
                    },
                    768: {
                        speed: 500,
                        slidesPerView: 2,
                        spaceBetween: 15,
                    },
                    320: {
                        speed: 600,
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                },
                on: {
                    init: (swiper) => {
                        if (
                            typeof swiper.params.slidesPerView === 'number' &&
                            slidesLength <= swiper.params.slidesPerView
                        ) {
                            swiper.disable();
                        }
                    },
                    resize: (swiper) => {
                        if (
                            typeof swiper.params.slidesPerView === 'number' &&
                            slidesLength <= swiper.params.slidesPerView
                        ) {
                            swiper.disable();
                        } else {
                            swiper.enable();
                        }
                    },
                },
            });
        }
    }
}

const _module = { init };

export default _module;

const createArrows = (container, { color = '3C3C3C', arrowClass = '', arrowWrapperClass = '' }) => {
    const track = container.querySelector('.glide__track');
    track.innerHTML += `
        <div class="glide__arrows ${arrowWrapperClass}" data-glide-el="controls">
            <button class="glide__arrow about-slider__arrow ${arrowClass} glide__arrow--left" data-glide-dir="<" aria-label="Предыдущий слайд">
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 1L1 6.46026L7 12" stroke="#${color}"/>
                </svg>
            </button>
            <button class="glide__arrow about-slider__arrow ${arrowClass} glide__arrow--right" data-glide-dir=">" aria-label="Следующий слайд">
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.00779 11.4326L7.07983 6L1.00779 0.488303" stroke="#${color}"/>
                </svg>
            </button>
        </div>
    `;
};

export default createArrows;

import debounce from 'lodash.debounce';

function generateHTML(searchResult) {
    return searchResult.map(result => {
        const domain = result.domain.startsWith('http') ? result.domain : `https://${result.domain}`;

        if (result.checked) {
            return `
                <li class="city-picker-cities__item text-xs">
                    <a href="${domain}" class="link city__checked" data-city="${result.name}" data-city-id="${result.id}">
                        <span>${result.name}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15">
                            <path d="M9 16.2l-4.2-4.2-1.4 1.4L9 19l11-11-1.4-1.4z" fill="#fff"/>
                        </svg>
                    </a>
                </li>
            `;
        }
        return `
            <li class="city-picker-cities__item text-xs">
                <a href="${domain}" class="link" data-city="${result.name}" data-city-id="${result.id}">${result.name}</a>
            </li>
        `;
    }).join('');
}

function renderHTML(html) {
    const cityListContainer = document.querySelector('.js-city-list');
    if (cityListContainer) {
        cityListContainer.innerHTML = html;
    }
}

function sendRequest(query) {
    const citySearchInput = document.querySelector('.js-city-search');
    citySearchInput.disabled = true;

    let sessid = null;
    if (typeof BX !== 'undefined') {
        sessid = BX.bitrix_sessid();
    }

    return fetch('/local/ajax/city_search.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Bitrix-Csrf-Token': sessid,
        },
        body: JSON.stringify({
            query_search: query,
            check_val: document.querySelector('.check_val.city-search').value,
            lang: document.documentElement.lang,
            sessid,
        }),
    })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                return data.result;
            }
            throw new Error(data.message);
        })
        .finally(() => {
            citySearchInput.disabled = false;
            citySearchInput.focus();
        });
}

function init() {
    const cityElements = document.querySelectorAll('.js-city-picker__item');

    const cities = Array.from(cityElements).map(cityElement => {
        const link = cityElement.querySelector('a');
        if (link) {
            return {
                name: link.textContent.trim(),
                domain: link.getAttribute('href'),
                id: link.getAttribute('data-city-id'),
                checked: link.classList.contains('city__checked'),
            };
        }
    }).filter(city => !!city);

    const citySearchInput = document.querySelector('.js-city-search');

    const debouncedSearch = debounce(function () {
        const searchQuery = citySearchInput.value.trim();

        if (searchQuery.length <= 2) {
            renderHTML(generateHTML(cities));
            return;
        }

        sendRequest(searchQuery).then(searchResult => {
            if (searchResult.length > 0) {
                renderHTML(generateHTML(searchResult));
            } else {
                renderHTML('<li class="city-picker-cities__item text-xs"><div class="">Ничего не найдено</div></li>');
            }
        });
    }, 750);

    citySearchInput.addEventListener('input', debouncedSearch);
}

export default { init };

import { Swiper, Scrollbar, FreeMode } from 'swiper';

function init(containers) {
    containers.forEach((container) => {
        const sliderElements = Array.from(container.querySelectorAll('.js-slider'));
        sliderElements.forEach((el) => {
            if (Array.from(el.querySelectorAll('.swiper-slide')).length > 1) {
                const slider = new Swiper(el, {
                    modules: [Scrollbar, FreeMode],
                    slidesPerView: 'auto',
                    freeMode: true,
                    spaceBetween: 10,
                    scrollbar: {
                        el: ".swiper-scrollbar",
                        hide: false,
                        draggable: true
                    },
                });
            }
        });
    });
}

const _module = { init };

export default _module;

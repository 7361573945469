import Cookies from 'cookies-js';
import delegate from 'delegate';
import Modal from '../../modules/modal';

let cityPickPopup;
let cityAlertPopup;
let currentCityEl;
let isMobile;
let delegation;
let desctopWrapper;
let mobileWrapper;
let cityConfirmBtn;
let currentCityId;

function onCityPick(e) {
    e.preventDefault();
    if (e.delegateTarget && e.delegateTarget.dataset.city && e.delegateTarget.dataset.cityId) {
        const currentCity = e.delegateTarget.dataset.city;
        const currentCityId = e.delegateTarget.dataset.cityId;
        const currentCityEl = document.querySelector('.js-city-picker-current');
        Cookies.expire('CURRENT_CITY');
        Cookies.set('CURRENT_CITY', currentCity, { expires: 365, path: '/' });
        currentCityEl.innerText = currentCity;
        cityPickPopup.close();
        requestCity(currentCityId);
    }
}

function onCityConfirm(e) {
    e.preventDefault();
    const currentCity = document.querySelector('.js-city-pick-link.city--active');
    currentCityId = currentCity.dataset.cityId;
    const currentCityEl = document.querySelector('.js-city-picker-current');
    Cookies.expire('CURRENT_CITY');
    Cookies.set('CURRENT_CITY', currentCityId, { expires: 365, path: '/' });
    currentCityEl.innerText = currentCity.dataset.city;
    cityPickPopup.close();
    requestCity(currentCityId);
}

function onload() {
    if (Cookies.get('CURRENT_CITY') && Cookies.get('CURRENT_CITY') !== '') {
        const currentCityElInPopup = document.querySelector(`[data-city-id="${Cookies.get('CURRENT_CITY')}"]`);
        const currentCityOnLoad = document.querySelector('.js-city-pick-link.city--active');
        if (currentCityOnLoad) {
            currentCityOnLoad.classList.remove('city--active');
        }
        currentCityElInPopup.classList.add('city--active');
        currentCityEl.innerHTML = currentCityElInPopup.innerHTML;
    }
}

const requestCity = async (city) => {
    const form = document.querySelector('.js-change-city-form');
    const formData = new FormData(form);
    formData.append('CURRENT_CITY', city);
    const res = await fetch(form.getAttribute('action'), {
        method: 'POST',
        body: formData,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.success && currentCityId !== city) {
                window.location.reload();
            }
        })
        .catch(() => {})
        .finally(() => {});
};

function initPopups() {
    let hasOpenedModal =
        document.body.classList.contains('no-scroll') && document.body.classList.contains('modal--open');
    cityAlertPopup = new Modal('city-alert', {
        beforeOpen() {
            document.body.classList.remove('no-scroll');
            document.body.classList.remove('modal--open');
        },
        beforeClose() {
            hasOpenedModal =
                document.body.classList.contains('no-scroll') && document.body.classList.contains('modal--open');
        },
        afterClose() {
            if (hasOpenedModal) {
                document.body.classList.add('no-scroll');
                document.body.classList.add('modal--open');
            }
        },
    });
    cityAlertPopup.init();

    cityPickPopup = new Modal('city-pick', {
        beforeOpen() {
            if (cityAlertPopup.isOpen) {
                cityAlertPopup.close();
            }
            const cityPicker = document.querySelector('.js-city-picker');
            cityPicker.classList.add('is-open');
            document.querySelector(".nav-modal-container .nav-list").classList.add("no-scroll");
            document.querySelector("header").classList.add("btn-close-is-hidden");
            if (!isMobile) {
                document.body.classList.remove('no-scroll');
                document.body.classList.remove('modal--open');
            } else {
                window.menu.open();
                const menu = document.querySelector('[data-modal="menu"]');
                const menuContainer = menu.querySelector('.nav-modal-container');
                const offset = isMobile ? 0 : mobileWrapper.offsetTop;
                menuContainer.scrollTo({
                    top: offset,
                    behavior: 'smooth',
                });
            }
        },
        afterOpen() {
            if (isMobile) {
                const cityPicker = document.querySelector('.js-city-picker');
                cityPicker.removeAttribute('data-modal-open');
                cityPicker.addEventListener(
                    'click',
                    function () {
                        cityPickPopup.close();
                    },
                    { once: true },
                );
            }
        },
        beforeClose() {
            hasOpenedModal =
                document.body.classList.contains('no-scroll') && document.body.classList.contains('modal--open');
        },
        afterClose() {
            const cityPicker = document.querySelector('.js-city-picker');
            cityPicker.classList.remove('is-open');
            document.querySelector(".nav-modal-container .nav-list").classList.remove("no-scroll");
            document.querySelector("header").classList.remove("btn-close-is-hidden");
            if (isMobile) {
                cityPicker.addEventListener(
                    'click',
                    function () {
                        cityPickPopup.open();
                    },
                    { once: true },
                );

                if (hasOpenedModal) {
                    document.body.classList.add('no-scroll');
                    document.body.classList.add('modal--open');
                }
            }
        },
    });
    cityPickPopup.init();
}

function checkDevice() {
    isMobile = window.matchMedia('(max-width: 1023px)').matches;
    if (desctopWrapper && mobileWrapper) {
        if (isMobile) {
            if (desctopWrapper && desctopWrapper.innerHTML !== '') {
                mobileWrapper.innerHTML = desctopWrapper.innerHTML;
            }
            desctopWrapper.innerHTML = '';
        } else {
            if (mobileWrapper.innerHTML !== '') {
                desctopWrapper.innerHTML = mobileWrapper.innerHTML;
            }
            mobileWrapper.innerHTML = '';
        }
    }
    onload();
}

function init(container = document) {
    currentCityEl = container.querySelector('.js-city-picker-current');
    delegation = delegate('.js-city-pick-link', 'click', onCityPick);
    desctopWrapper = container.querySelector('.js-city-picker-wrapper');
    mobileWrapper = container.querySelector('.js-mobile-city-container');
    cityConfirmBtn = container.querySelector('.js-confirm-city');
    if (cityConfirmBtn && cityConfirmBtn.dataset.cityId) {
        currentCityId = cityConfirmBtn.dataset.cityId;
    }

    if (cityConfirmBtn) {
        cityConfirmBtn.addEventListener('click', onCityConfirm);
    }

    checkDevice();

    initPopups();
    onload();

    window.addEventListener('resize', checkDevice);
}

function destroy(container = document) {
    cityPickPopup = null;
    delegation.destroy();
    cityConfirmBtn.removeEventListener('click', onCityConfirm);
    cityConfirmBtn = null;
    window.removeEventListener('resize', checkDevice);
}

const _module = { init, destroy };

export default _module;

import Modal from '../../modules/modal';

function init() {
    const mapPopupEl = document.querySelector('[data-modal="map-popup"]');
    const mapContainer = document.querySelector('.js-map-container');
    if (mapPopupEl) {
        const mapPopup = new Modal('map-popup');
        mapPopup.init();
        if (window.matchMedia('(min-width: 1025px)').matches) {
            mapPopup.open();
        }

        const mapLinks = document.querySelectorAll('.js-map-link');
        mapLinks.forEach(link => link.addEventListener('click', () => {
            if (window.matchMedia('(max-width: 1024px)').matches) {
                mapPopup.close();
            }

            if (mapContainer) {
                const mapPosition = mapContainer.offsetTop;
                window.scrollTo({
                    top: mapPosition,
                    behavior: 'smooth'
                })
            }
        }))
    }

    const mapCityEl = document.querySelector('[data-modal="city-map-pick"]');
    const mapCityElTrigger = document.querySelector('[data-modal-open="city-map-pick"]');
    const mapCityElCloseTrigger = document.querySelectorAll('[data-modal-close="city-map-pick"]');

    const modalTriggerHandler = () => {
        mapCityEl.classList.toggle('app-modal--opened');
        if (mapPopupEl) {
            mapPopupEl.classList.toggle('is-light');

        }
    }

    if (mapCityEl && mapCityElTrigger) {
        mapCityElTrigger.addEventListener('click', modalTriggerHandler)
        mapCityElCloseTrigger.forEach(btn => btn.addEventListener('click', modalTriggerHandler))
    }
}

const _module = { init };

export default _module;

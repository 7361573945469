import { listenOnce /* , timeout */ } from '../utils';

/**
 * @param {HTMLElement} asset - HTMLImageElement, HTMLVideoElement, HTMLAudioElement, etc.
 * @returns {boolean}
 */
export const isAssetReady = (asset) =>
    !!asset.complete ||
    !!asset.href ||
    !!asset.getAttribute('xlink:href') ||
    asset.readyState === 4 ||
    (!asset.src && asset.classList.contains('lazy')) ||
    (asset.src && asset.getAttribute('loading') === 'lazy');

/**
 * @returns {Promise<void>}
 */
export default () => {
    const assets = Array.from(document.querySelectorAll('img, video'));
    const preloader = document.querySelector('.js-preloader-dark');
    let loadedCount = 0;

    /**
     * Makes sure all the neccessary assets are loaded
     * before preloader is gone
     */
    const loadAssets = () => {
        return new Promise((resolve) => {
            const incrementLoaded = () => {
                loadedCount++;
                if (loadedCount == assets.length) {
                    resolve();
                }
            };

            if (assets.length === 0) {
                incrementLoaded();
            }

            assets.forEach((asset) => {
                if (isAssetReady(asset)) {
                    incrementLoaded();
                } else {
                    if (asset instanceof HTMLImageElement) {
                        listenOnce(asset, 'load', incrementLoaded);
                    }

                    if (asset instanceof HTMLVideoElement) {
                        listenOnce(asset, 'canplaythrough', function() {
                            incrementLoaded()
                        });
                    }

                    listenOnce(asset, 'error', incrementLoaded);
                }
            });
        });
    };

    const init = async () => {
        if (preloader) {
            await loadAssets();
            preloader.classList.add('preloader--leave');
        }
    };

    return new Promise((resolve) => {
        init().then(resolve);
    });
};

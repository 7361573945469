import { setCount } from './map-search';

function setPhoneNumberForLink(phone) {
    // удаляем все нецифры
    phone = phone.replace(/\D+/g, '');
    // добавляем +7
    phone = "+7" + phone.slice(1);

    return phone;
}

function setPhoneNumber(el) {
    const phoneNumber = el.getAttribute('data-phone');
    if (phoneNumber) {
        const newPhone = setPhoneNumberForLink(phoneNumber);
        const phoneContainer = document.querySelector('.js-contacts-phone');
        if (phoneContainer) {
            phoneContainer.textContent = phoneNumber;
            phoneContainer.href = `tel:${newPhone}`;
        }
    }
}

function setOffices(el) {
    const cityAddresses = document.querySelectorAll('.js-map-city-address');
    const selectedCity = el.textContent.toLocaleLowerCase();
    let countResults = 0;
    cityAddresses.forEach(city => {
        const cityValue = city.textContent.toLocaleLowerCase();
        const office = city.closest('.js-map-search-office');

        if (cityValue.includes(selectedCity)) {
            office?.classList.remove('is-hide');
            countResults++;
        } else {
            office?.classList.add('is-hide');
        }
    })

    setCount(countResults, true);
}

function clearSearch() {
    const citySearch = document.querySelector('.js-map-search-input');

    if (citySearch) {
        citySearch.value = '';
    }
}

function setCity(e) {
    e.preventDefault();
    setPhoneNumber(e.target);
    setOffices(e.target);
    clearSearch();
    const container = e.target.closest('.js-map-city-container');
    if (!container) return;
    const currentCityContainer = container.querySelector('.js-map-city-current');
    currentCityContainer.textContent = e.target.textContent;
}

function init() {
    const mapCityEls = document.querySelectorAll('.js-map-city-trigger');
    mapCityEls.forEach(el => {
        el.addEventListener('click', setCity);
        if (el.classList.contains('is-selected')) {
            setOffices(el);
        }
    })
}

const _module = { init };

export default _module;

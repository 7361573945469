import { withLeadingZero } from './';
const createArrows = (container, length) => {
    const track = container.querySelector('.glide__track');
    track.innerHTML += `
    <div class="slider-counter slider-counter--animated js-slider-counter">
        <div class="slider-counter__current js-slider-counter__current">01</div>
        <div class="slider-counter__total js-slider-counter__total">${withLeadingZero(length)}</div>
    </div>
    `;
};

export default createArrows;
